<template>
  <section class="mv">
    <div class="mv__img"></div>
  </section>
</template>

<style scoped lang="scss">
.mv {
  width: 100%;
}

.mv__img {
  background: url("../assets/images/mv.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding-top: 56%;
}
</style>
