<template>
  <a :href="url" target="_blank" class="c-primaryButton">
    {{ text }}
  </a>
</template>

<style scoped lang="scss">
.c-primaryButton {
  background: #d1e4dd;
  display: block;
  width: 100%;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
  margin: 0 0 0 auto;
}
</style>

<script>
export default {
  name: "PrimaryButton",
  props: ["url", "text"],
};
</script>
