<template>
  <section class="picture"></section>
</template>

<style scoped lang="scss">
.picture {
  margin-top: 120px;
  background: url("../assets/images/picture.jpg") no-repeat;
  background-size: 100%;
  background-position: top;
  width: 100%;
  padding-top: 30%;

  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }
}
</style>
