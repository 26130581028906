<template>
  <section class="about">
    <div class="about__img">
      <img src="../assets/images/tokomaru.jpg" />
    </div>

    <div class="about__textWrap">
      <h2 class="about__header">本大会について</h2>
      <p class="about__text">三重とこわか国体の代替大会になります</p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 60px;
    padding: 0 20px;
  }
}

.about__img {
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  img {
    width: 75%;

    @media screen and (max-width: 768px) {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
  }
}

.about__textWrap {
  width: 30%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
