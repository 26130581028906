<template>
  <ul class="other">
    <li class="other__item">
      <router-link to="/tournament">
        <div class="other__img other__img--tournament"></div>
        <p class="other__text">組み合わせ情報</p>
      </router-link>
    </li>
    <li class="other__item">
      <router-link to="/practice">
        <div class="other__img other__img--practice"></div>
        <p class="other__text">練習割当情報</p>
      </router-link>
    </li>
    <li class="other__item">
      <router-link to="/parking">
        <div class="other__img other__img--parking"></div>
        <p class="other__text">駐車場情報</p>
      </router-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.other {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  max-width: 1240px;
  margin: 120px auto 0;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0 20px;
  }
}
.other__item {
  width: calc(100% / 3);
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  & + .other__item {
    margin-left: 20px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
}
.other__img {
  padding-top: 60%;
  border-radius: 15px;

  @media screen and (max-width: 768px) {
    padding-top: 50%;
  }

  &.other__img--tournament {
    background: url("../assets/images/other/tournament.jpg") no-repeat center;
    background-size: 100%;
  }
  &.other__img--practice {
    background: url("../assets/images/other/practice.jpg") no-repeat center;
    background-size: 100%;
  }
  &.other__img--parking {
    background: url("../assets/images/other/parking.jpg") no-repeat center;
    background-size: 100%;
  }
}
</style>
