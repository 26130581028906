<template>
  <main class="l-container">
    <Mv />

    <About />

    <Picture />

    <YouTube />

    <Vote />

    <Other />
  </main>
</template>

<script>
import Mv from "../components/Mv.vue";
import About from "../components/About.vue";
import Picture from "../components/Picture.vue";
import YouTube from "../components/YouTube.vue";
import Vote from "../components/Vote.vue";
import Other from "../components/Other.vue";

export default {
  name: "Home",
  components: {
    Mv,
    About,
    Picture,
    YouTube,
    Vote,
    Other,
  },
};
</script>
