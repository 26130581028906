<template>
  <section class="youtube">
    <div class="youtube__textWrap">
      <h2 class="youtube__header">公式YouTubeについて</h2>
      <p class="youtube__text">
        全試合、こちらのYouTubeチャンネルよりLIVE配信いたします。
      </p>
      <div class="youtube__button">
        <ul class="youtube__areaLinks">
          <li>
            <PrimaryButton
              url="https://www.youtube.com/channel/UCzCwUX0GD9wch800s2hnh8Q"
              text="メインチャンネル"
            />
          </li>
          <li>
            <PrimaryButton
              url="https://www.youtube.com/channel/UCyz6ypaIG-BPZshO1sJbKyw"
              text="サブチャンネル"
            />
          </li>
          <li v-for="item in items" :key="item.title">
            <PrimaryButton :url="item.url" :text="item.title" />
          </li>
        </ul>
      </div>
    </div>

    <div class="youtube__img">
      <img src="../assets/images/youtube.jpg" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.youtube {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  max-width: 1240px;
  margin: 120px auto 0;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 60px;
  }
}
.youtube__img {
  width: 50%;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: 15px;
  }
}

.youtube__textWrap {
  width: 50%;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
}

.youtube__button {
  margin-top: 40px;
}
.youtube__areaLinks {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  li {
    width: calc(100% / 2);
    margin-top: 10px;
  }
}
</style>

<script>
import PrimaryButton from "../components/button/PrimaryButton.vue";
import axios from "axios";

export default {
  components: {
    PrimaryButton,
  },
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    getData: async function () {
      try {
        const response = await axios.get(
          "https://vszv8q21i2.execute-api.us-east-1.amazonaws.com/tokowaka"
        );
        this.items = response.data.links;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
