<template>
  <section class="vote">
    <div class="vote__img">
      <img src="../assets/images/vote.jpg" />
    </div>

    <div class="vote__textWrap">
      <h2 class="vote__header">寄付について</h2>
      <p class="vote__text">
        LIVE配信、その他諸経費を支援いただく寄付になります。
      </p>
      <p class="vote__text vote__text--notice">
        ※寄付いただきました方には現在申請中のクラウドファンディングに準拠した返礼品をご用意させていただいております。<br />
        詳細は追ってアップさせていただきます。
      </p>
      <div class="vote__button">
        <PrimaryButton
          url="https://buy.stripe.com/00g16l1s0dzD8Ug3cd"
          text="1,000円"
        />
        <PrimaryButton
          url="https://buy.stripe.com/00g3et7QoeDHfiEcMM"
          text="10,000円"
        />
        <PrimaryButton
          url="https://buy.stripe.com/9AQaGV8Us537c6scMO"
          text="100,000円"
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.vote {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  max-width: 1240px;
  margin: 120px auto 0;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 60px;
    padding: 0 20px;
  }
}

.vote__img {
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
    order: 2;
  }
  img {
    width: 100%;
    border-radius: 15px;
  }
}

.vote__textWrap {
  width: 50%;
  margin-left: 40px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    order: 1;
  }
}

.vote__text {
  &--notice {
    font-size: 14px;
    font-weight: bold;
  }
}

.vote__button {
  margin-top: 40px;
}

.c-primaryButton {
  margin-top: 20px;
}
</style>

<script>
import PrimaryButton from "../components/button/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },
};
</script>
